import React, {useState, useEffect} from "react";
import {Link, Trans, useTranslation, useI18next, I18nextContext} from 'gatsby-plugin-react-i18next';
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
import {graphql} from 'gatsby';
import hubspotSettings from "../../../constants/company/settings";
import UpgraderModal from '../../../components/modals/upgrader-modal';


const IndexPage = (props) => {
  const {t} = useTranslation();
  const {changeLanguage} = useI18next();
  const context = React.useContext(I18nextContext);
  const [emailIsClicked, setEmailIsClicked] = useState(false);
  const firstNameInputRef = React.useRef(null);
  const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&')
  };

  function handleScrollTo(elmID, offset) {
    window.scroll({
      behavior: 'smooth',
      left: 0,
      top: document.getElementById(elmID).offsetTop - offset
    });
  }

  function handleEmailClicked() {
    setEmailIsClicked(!emailIsClicked);
    document.getElementById('company').focus();
  }

  function handleSupportChatClick() {
    window.history.pushState({}, 'bot', '?bot=support');
    window.HubSpotConversations.clear({resetWidget: true});
  }

  useEffect(() => {
    if (document.location.hash === '#gotoFaq') {
      handleScrollTo("genFaq", 150);
    }
    if (document.location.hash === '#gotoSmartLocks') {
      handleScrollTo("SmartLocks", 200);
    }
    if (document.location.hash === '#gotoElectronicLocks') {
      handleScrollTo("ElectronicLocks", 200);
    }
    if (document.location.hash === '#contactInfo') {
      handleScrollTo("contactInfo", 200);
    }
  }, []);

  return (
      <Layout>
        <Seo
            title={t('support_product_registration')}
            description={t('des_support_product_registration')}
        />
        <div className="support product-registration">
          <section className="hero">
            <div className="container">
              <h1>
                <Trans>product_registration</Trans>
              </h1>
              <p><Trans>product_registration_hl</Trans></p>
            </div>
          </section>

          <section id={'contactInfo'} className="contact-info">
            <div className="container contact-card-container">
              <div className={'sales-form show'}>
                <form id={'supportForm'}
                      className={'sales-contact-form'}
                      action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8&orgId=00Dj0000001uqJu"
                      method="POST">
                  <div className="input-container company-name">
                    <label htmlFor={'company'}><Trans>company_name</Trans>*</label>
                    <input id="company" required="required" maxLength="80" name="company" type="text"/>
                  </div>
                  <div className="input-container first-name">
                    <label htmlFor={'first_name'}><Trans>first_name</Trans>*</label>
                    <input id="first_name" required="required" maxLength="80" name="first_name" type="text"/>
                  </div>
                  <div className="input-container first-name">
                    <label htmlFor={'last_name'}><Trans>last_name</Trans>*</label>
                    <input id="last_name" required="required" maxLength="80" name="last_name" type="text"/>
                  </div>
                  <div className="input-container email">
                    <label htmlFor={'email'}><Trans>email</Trans>*</label>
                    <input id="email" required="required" maxLength="80" name="email" type="text"/>
                  </div>
                  <div className="input-container phone-number">
                    <label htmlFor={'phone'}><Trans>phone_number</Trans></label>
                    <input id="phone" maxLength="40" name="phone" type="text"/>
                  </div>
                  <div className="input-container email">
                    <label htmlFor={'00N3Z00000CvIOl'}><Trans>product_serial_number</Trans></label>
                    <input id="00N3Z00000CvIOl" maxLength="30" name="00N3Z00000CvIOl" type="text"/>
                  </div>
                  <div className="input-container phone-number">
                    <label htmlFor={'00N3Z00000CWiCF'}><Trans>sales_order_number</Trans></label>
                    <input id="00N3Z00000CWiCF" maxLength="255" name="00N3Z00000CWiCF" type="text"/>
                  </div>
                  <div className="input-container phone-number">
                    <label htmlFor={'00N3Z00000CvIOv'}><Trans>date_of_purchase</Trans></label>
                    <input id="00N3Z00000CvIOv" maxLength="12" name="00N3Z00000CvIOv" placeholder={'mm/dd/yyyy'} type="text"/>
                  </div>
                  <div className="input-container phone-number">
                    <label htmlFor={'00N3Z00000CvIPF'}><Trans>quantity</Trans></label>
                    <input id="00N3Z00000CvIPF" maxLength="20" name="00N3Z00000CvIPF" type="text"/>
                  </div>
                  <div className="input-container message">
                    <label htmlFor="description"><Trans>message</Trans></label>
                    <textarea name="description"
                              id={'description'}
                              maxLength="250"
                    />
                    <p><Trans>product_registration_details</Trans></p>
                  </div>
                  <input type="hidden" name="oid" value="00Dj0000001uqJu"/>
                  <input type="hidden" name="lead_source" value="Website"/>
                  <input type="hidden" id="00NKZ000000VIW9" name="00NKZ000000VIW9" value="1"/>
                  <input type="hidden" id="00N3Z00000CvLhb" name="00N3Z00000CvLhb" value="Product Registration Form"/>
                  <input
                      type="hidden"
                      name="retURL"
                      value={context.language !== 'en' ?
                          'https://www.digilock.com/' + context.language + '/support/product-registration/thank-you/' :
                          'https://www.digilock.com/support/product-registration/thank-you/'}
                  />
                  <div className="input-container-submit support">
                    <input
                        onClick={handleEmailClicked}
                        className={'form-submit cancel'}
                        type={'reset'}
                    >Cancel</input>
                    <input
                        className={'form-submit'}
                        type="submit"
                        name={'submit'}
                        // aria-disabled={!isFormValid ? 'true' : 'false'}
                    />
                  </div>
                </form>
              </div>
            </div>
          </section>
          <UpgraderModal showLink={false}/>
        </div>
      </Layout>
  )
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allSupportGeneralFaqXlsxLockFunctionality {
      edges {
        node {
          id
            title
            content
        }
      }
    }
    allSupportGeneralFaqXlsxSetupProgramming {
    edges {
      node {
        id
          title
          content
      }
    }
  }
  
  allSupportGeneralFaqXlsxTroubleShooting {
    edges {
      node {
        id
          title
          content
      }
    }
  }
  
  allSupportGeneralFaqXlsxOrdersSupport {
    edges {
      node {
        id
          title
          content
      }
    }
  }
  }
`;
